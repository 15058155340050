<template>
  <div :class="classObj" class="app-wrapper">

    <el-container style="height: 100%; ">

      <Nav :isCollapse="!$store.state.open" class="sidebar-container" />

      <el-container class="main-container">

        <el-header class="fixed-header">
          <i @click="openNav" class="icons"
             :class="( $store.state.open ? 'el-icon-s-unfold' : 'el-icon-s-fold' )" ></i>
          <div class="content">
            <p>{{ $store.state.user_info.role }}</p>
            <img src="https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80" alt="">
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ $store.state.user_info.name }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="updatePassWord" >修改密码</el-dropdown-item>
                <el-dropdown-item @click.native="loginOut" >退出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>

        <el-scrollbar wrap-class="scrollbar-wrapper" style="background: #EEEEEE;" >
          <section class="app-main">
            <transition name="fade-transform" mode="out-in">
              <div>
                <keep-alive>
                  <router-view v-if="$route.meta.keep" />
                </keep-alive>
                <router-view v-if="!$route.meta.keep" />
              </div>
            </transition>
          </section>
        </el-scrollbar>
      </el-container>

    </el-container>

    <el-dialog
        title="修改密码"
        :visible.sync="upDate"
        width="26%"
        @close="upDateClose"
        center>

      <div class="dia">
        <el-form label-width="70px"
                 @submit.native.prevent
                 :hide-required-asterisk="true"
                 ref="update_form"
                 :model="update_form"
                 :rules="update_rules" >
          <el-form-item label="旧密码" prop="oldPassword">
            <el-input type="password" show-password v-model="update_form.oldPassword" clearable placeholder="请输入旧密码"></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input type="password" show-password v-model="update_form.password" clearable placeholder="请输入新密码"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input type="password" show-password v-model="update_form.confirmPassword" clearable placeholder="请输入确认密码"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="button">
              <el-button @click="upDateClose">取消</el-button>
              <el-button type="primary" native-type="submit" @click="onSubmitUpdate(update_form)" >确认</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>

    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    var validatePass = (rule, value, callback) => {
      if(value !== this.update_form.password) {
        callback(new Error('两次输入密码不一致!'));
      }else {
        callback();
      }
    };
    return{
      classObj: {
        hideSidebar: !this.$store.state.open,
        openSidebar: this.$store.state.open,
      },

      upDate: false,
      update_form: {
        oldPassword: null,
        password: null,
        confirmPassword: null,
      },
      update_rules: {
        oldPassword: [
            { required: true, message: '请输入旧密码', trigger: 'change' },
            { min: 6, max: 20, message: '密码长度在 6 到 20 位', trigger: 'blur' }
        ],
        password: [
            { required: true, message: '请输入新密码', trigger: 'change' },
            { min: 6, max: 20, message: '密码长度在 6 到 20 位', trigger: 'blur' }
        ],
        confirmPassword: [
            { required: true, message: '请输入确认密码', trigger: 'change' },
            { min: 6, max: 20, message: '密码长度在 6 到 20 位', trigger: 'blur' },
            { validator: validatePass, trigger: 'blur' }
        ],
      }
    }
  },
  watch: {

  },
  created() {

  },
  mounted() {

  },
  methods: {
    openNav() {
      this.$store.commit("setOpen",!this.$store.state.open),
      this.classObj.hideSidebar= !this.$store.state.open;
      this.classObj.openSidebar= this.$store.state.open;

    },
    onSubmitUpdate(formName) {
      this.$refs["update_form"].validate((valid) => {
        if(valid) {
          this.$post("pc/admin/editPassword",formName)
            .then(res => {
              if(res) {
                console.log(res)
                this.$message({
                  type: 'success',
                  message: '密码修改成功!'
                });
                this.upDateClose()
              }
            })
        }
      })
    },
    upDateClose() {
      this.$refs["update_form"].resetFields();
      this.upDate= false;
    },
    // 退出
    loginOut() {
      this.$confirm("您确定退出御锦健康管理系统？")
          .then(() => {

            this.$post("login/quitAdmin")
              .then(res => {
                if(res) {
                  this.$router.replace({
                    path: "/"
                  })
                  // location.replace("/")
                }
              })

          })
          .catch(() => {

          });
    },
    // 修改密码
    updatePassWord() {
      this.upDate= true;
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../style/Index.scss";
</style>
